import { Backdrop, Box, Button, Checkbox, Chip, CircularProgress, Divider, MenuItem, Select, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import usersService from "../../services/usersService";
import RolloutService from "../../services/rolloutService/rolloutService"
import AlertSnackbar from "../../components/AlertSnackbar";

const featureList = [
  { label: 'Daily Wizard', value: 'dailyWizard' },
];
const RolloutFeaturePage = () => {
  const [selectedOption, setSelectedOption] = useState("dailyWizard")
  const [usersList, setUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [settingType, setSettingType] = useState(null);
  const [disableCheckboxColumn, setDisableCheckboxColumn] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    setDisableSave(false);
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'selected',
        headerName: 'Select',
        type: 'boolean',
        width: 100,
        sortable: true,
        renderCell: (params) => (
          <Checkbox
            disabled={disableCheckboxColumn}
            sx={{ padding: '0px' }}
            checked={selectedIds.includes(params?.row.userId)}
            onChange={() => handleCheckboxChange(params?.row.userId)}
            inputProps={{ 'aria-label': 'select user' }}
          />
        ),
        valueGetter: (params) => selectedIds.includes(params?.row.id),
        sortComparator: (v1, v2, params1, params2) => {
          const isSelected1 = selectedIds.includes(params1.id);
          const isSelected2 = selectedIds.includes(params2.id);
          return isSelected1 === isSelected2 ? 0 : isSelected1 ? -1 : 1;
        },
      },
      {
        field: 'name',
        headerName: "Name",
        width: 250,
        valueGetter: (params) => params?.trim()
      }, {
        field: 'email',
        headerName: "Email ID",
        width: 250,
        valueGetter: (params) => params?.trim()
      }, {
        field: 'facebookUserName',
        headerName: "Facebook Username",
        width: 300,
        valueGetter: (params) => params?.trim()
      }, {
        field: 'status',
        headerName: "Status",
        width: 200,
        renderCell: (params) => {
          return (
            <Chip
              label={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
              style={{
                backgroundColor: "#e6f7ed",
                color: "#1e4e31",
                padding: "1px 5px",
                fontWeight: "500",
              }}
              size="small"
            />
          );
        },
      },

    ];
  }, [disableCheckboxColumn,selectedIds]);

 

  const fetchRolloutSettings = async () => {
    try {
      setIsLoading(true);
      const params = { featureName: selectedOption }
      const resp = await RolloutService.getRolloutSettings(params);
      const rolloutSettings = resp?.data?.data
      if (rolloutSettings) {
        setSelectedIds(rolloutSettings.featureRolloutUsers)
        setSettingType(rolloutSettings.status);
        if (rolloutSettings.status === "selectedUsers") {
          setDisableCheckboxColumn(false);
        } else {
          setDisableCheckboxColumn(true);
        }
      } else {
        setSelectedIds([]);
        setSettingType(null);
      }

    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.message || "Something Went Wrong",
        severity: "error",
      })
    }
    setIsLoading(false);
  }
  const fetchAllUserDetails = async () => {
    try {
      const params = {
        status: 'active'
      }
      const respUsersDetails = await usersService.getAllUserDetails(params);
      const updatedUserDetails = respUsersDetails.data.data.users?.map(item => ({ ...item, name: `${item.firstName} ${item.lastName}` }))
      setUsersList(updatedUserDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSettingTypeChange = (newSettingType) => {
    if (newSettingType !== settingType) {
      setSettingType(newSettingType);
      if (newSettingType === "selectedUsers") {
        setDisableCheckboxColumn(false);
      } else {
        setSelectedIds([]);
        setDisableCheckboxColumn(true);
      }
      setDisableSave(false);
    }
  }

  const saveRolloutSettings = async (updatedSettingType, userList = []) => {
    try {
      setIsLoading(true);
      const payload = {
        featureName: selectedOption,
        status: updatedSettingType,
        users: userList
      }
      await RolloutService.setRolloutSettings(payload);
      setSnackbar({
        open: true,
        message: "Rollout Settings has been updated.",
        severity: "success",
      });
      setDisableSave(true);
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.message || "Something Went Wrong",
        severity: "error",
      });
    }
    setIsLoading(false);
  }
  useEffect(() => {
    fetchAllUserDetails();
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    fetchRolloutSettings();
  }, [selectedOption])

  return (
    <Box>
      <Typography variant="h1" gutterBottom sx={{ marginBottom: '20px' }}>
        Rollout Feature Settings
        <Select
          value={selectedOption}
          className="ml-8"
          onChange={(e) => { setSelectedOption(e.target?.value) }}
        >
          {featureList.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Typography>
      <Divider sx={{ marginBottom: "1em" }} />
      <Box display="flex" gap={2} justifyContent="space-between">
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button
            onClick={() => handleSettingTypeChange("enabled")}
            variant={settingType === "enabled" ? "contained" : "outlined"}
          >
            Enable for All
          </Button>
          <Button
            onClick={() => handleSettingTypeChange("disabled")}
            variant={settingType === "disabled" ? "contained" : "outlined"}
          >
            Disable for All
          </Button>
          <Button
            onClick={() => handleSettingTypeChange("selectedUsers")}
            variant={settingType === "selectedUsers" ? "contained" : "outlined"}
          >
            Select User
          </Button>
        </Box>
        <Box>
          <Button variant="contained" disabled={disableSave} onClick={() => { saveRolloutSettings(settingType, selectedIds) }}>Save</Button>
        </Box>
      </Box>
      <Box mb={3} mt={2}>
        {usersList.length > 0 ? (
          <DataGrid
            rows={usersList}
            columns={columns}
            getRowId={(row) => row.userId}
            checkboxSelection={false}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 20]}
            isRowSelectable={() => settingType === "selectedUsers"}
          />
        ) : (
          <Typography variant="body1" align="center" sx={{ mt: 4 }}>
            No records found
          </Typography>
        )}
      </Box>
      {isLoading && (<Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>)}
      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Box>

  )
}
export default RolloutFeaturePage;